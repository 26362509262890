import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArredondamentoPipe } from '../arredondamento.pipe';
import { ArrendodarPipe } from '../arrendodar.pipe';
import { ConverterDataPipe } from '../converter-data.pipe';
import { FormatarDinheiroPipe } from '../formatar-dinheiro.pipe';
import { GroupEstoquePipe } from '../group-estoque.pipe';



@NgModule({
  declarations: [ArredondamentoPipe, ArrendodarPipe, ConverterDataPipe, FormatarDinheiroPipe, GroupEstoquePipe],
  imports: [
    CommonModule
  ],
  exports: [ArredondamentoPipe, ArrendodarPipe, ConverterDataPipe, FormatarDinheiroPipe, GroupEstoquePipe]
})
export class ModuleModule { }
