import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'converterData'
})
export class ConverterDataPipe implements PipeTransform {

  public data: any;
    transform(value){
       if(value){            
            this.data = value;
            return this.data.substr(8,2)+"/"+this.data.substr(5,2)+"/"+this.data.substr(0,4);
        }
        // console.log(value);
        return value;
    }
}
