import { Component, OnInit } from '@angular/core';
import { SQLiteObject } from '@ionic-native/sqlite/ngx';
import { DatabaseService } from '../../api/database.service';
import { LoadingService } from '../../api/loading.service';
import { TabsContagemPage } from '../tabs-contagem/tabs-contagem.page';

@Component({
  selector: 'app-contagem-detalhe',
  templateUrl: './contagem-detalhe.page.html',
  styleUrls: ['./contagem-detalhe.page.scss'],
})
export class ContagemDetalhePage implements OnInit {
  public products: any[] = [];
  constructor(
    private dbProvider: DatabaseService,
    private tabs: TabsContagemPage,
    private loader: LoadingService) { }

  ngOnInit() {
  }

  ionViewWillLeave(){
    this.products = [];
  }

  ionViewWillEnter() {    
     // = data.rows.item(0).pedido;
    this.buscarBalancoItens(this.tabs.pedidoBalanco2.pedido);
  }

  public buscarBalancoItens(pedido) {
    this.products = [];
    return this.dbProvider.getDB()
      .then((db: SQLiteObject) => {
        let sql = 'SELECT * FROM balanco_itens p where p.pedido = ? order by id desc';
        var data: any[] = [pedido];
        // filtrando pelo nome
        return db.executeSql(sql, data)
          .then((data: any) => {
            if (data.rows.length > 0) {
              for (var i = 0; i < data.rows.length; i++) {
                var product = data.rows.item(i);
                this.products.push(product);
              }
              return this.products;
            } else {
              return [];
            }
          })
          .catch((e) => console.error(e));
      })
      .catch((e) => console.error(e));
  }

  public itemSelected(item){
    this.remove(item.ID);
    for(let i = 0; i < this.products.length; i++) {
      //console.log(this.products[i]['ID'])
      if(this.products[i]['ID'] == item.ID){
        this.tabs.pedidoBalanco2.quantidade = this.tabs.pedidoBalanco2.quantidade - item.quantidade;
        this.products.splice(i, 1);
      }
    }    
  }

  public remove(id) {
    return this.dbProvider.getDB()
      .then((db: SQLiteObject) => {
        let sql = 'delete from balanco_itens where id = ?';
        let data = [id];
        return db.executeSql(sql, data).then((e)=>{
          this.loader.presentToast('Item apagado com sucesso!');
        })
          .catch((e) => console.error(e));
      })
      .catch((e) => console.error(e));
  }

}
