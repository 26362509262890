import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatarDinheiro'
})
export class FormatarDinheiroPipe implements PipeTransform {

  public data: any;
    transform(value){
       if(value){            
            this.data = value;
            return this.data.toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+\,)/g, "$1.");
        }
        // console.log(value);
        return value;
    }

}
