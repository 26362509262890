import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrendodar'
})
export class ArrendodarPipe implements PipeTransform {

  public data: any;
    transform(value){
       if(value){            
            this.data = value;
            return this.data.toFixed(2);
        }        
        return value;
    }

}
