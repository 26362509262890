import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'groupEstoque'
})
export class GroupEstoquePipe implements PipeTransform {

  public data :any;
  transform(items) {
    if(items){            
      this.data = items;
      // a.substr(0,4) ANO
      // a.substr(5,2) MES
      // a.substr(8,2) DIA
      return this.data.substr(8,2)+"/"+this.data.substr(5,2)+"/"+this.data.substr(0,4);
      
  }
  //return this.data;    
  }

}
